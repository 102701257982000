import dayjs from 'dayjs';
import { type FunctionComponent } from 'react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import {
  ContractUserAuthenticationMethodEnum,
  MypageDealTaskGroupEnum,
  MypageDealTaskStatusEnum,
  MypageDealTaskTypeEnum } from
'~/gql/generated/graphql';
import styles from '~/styles/page/mypage/deals/dealsShow/task-list.module.css';

const blockClass = 'task-list';

const TaskListDealFragment = graphql(`
  fragment TaskList_deal on Deal {
    id
    dealContractDeadline
    dealContractSentEmail
    dealTasks {
      id
      taskType
      completed
    }
    dealDetail {
      id
      expectedCarRegistrationCompletionDate
      deliveryDate
      deliveryTimeRangeText
    }
    mypageDealTasks {
      type
      group
      status
      title
    }
    dealContract {
      id
      userAuthenticationMethod
      deadline
      email
      smsTel
    }
  }
`);

type TaskItemProps = {
  taskStatus: MypageDealTaskStatusEnum;
  title: string;
  deadline?: string;
  description?: string;
  link?: string;
  linkText?: string;
};

const TaskItem: FunctionComponent<TaskItemProps> = (props) => {
  const { taskStatus, title, deadline, description, link, linkText } = props;

  return (
    <div className={`${styles[`${blockClass}__item`]}`}>
      {taskStatus === MypageDealTaskStatusEnum.Completed ?
      <div className={`${styles[`${blockClass}__item-check`]} ${styles[`${blockClass}__item-check--active`]}`}>
          <img src={'/images/icons/ic_check.svg'} alt='check' />
        </div> :

      <div className={styles[`${blockClass}__item-check`]} />
      }
      <div
        className={[
        styles[`${blockClass}__item-tile`],
        taskStatus === MypageDealTaskStatusEnum.Preparing ? styles[`${blockClass}__item-tile--preparing`] : '',
        taskStatus === MypageDealTaskStatusEnum.Completed ? styles[`${blockClass}__item-tile--completed`] : ''].
        join(' ')}>

        {taskStatus === MypageDealTaskStatusEnum.Preparing &&
        <span className={styles[`${blockClass}__item-tile__mark`]}>準備中</span>
        }
        {taskStatus === MypageDealTaskStatusEnum.Completed &&
        <p className={styles[`${blockClass}__item-tile__complete`]}>BUDDICA確認済</p>
        }
        <p className={styles[`${blockClass}__item-tile__title`]}>{title}</p>
        {deadline &&
        <span className={styles[`${blockClass}__item-tile__deadline`]}>{`期限：〜${dayjs(deadline).format(
            'YYYY/MM/DD'
          )}`}</span>
        }
        {description &&
        <div className={styles[`${blockClass}__item-tile__description`]}>
            {description.split(/\n/).map((text, index) =>
          <p key={index}>{text}</p>
          )}
          </div>
        }
        {link &&
        <a href={link} className={styles[`${blockClass}__item-tile__link`]}>
            <img src={'/images/icons/ic_manual.svg'} alt='manual' />
            <span>{linkText || 'マニュアルを見る'}</span>
          </a>
        }
      </div>
    </div>);

};

type NextArrowProps = {
  text: string;
  active: boolean;
};

const NextArrow: FunctionComponent<NextArrowProps> = ({ text, active }) => {
  return (
    <p className={`${styles[`${blockClass}__next`]} ${active && styles[`${blockClass}__next--active`]}`}>{text}</p>);

};

type DocumentCreatingTasksProps = {
  deal?: FragmentType<typeof TaskListDealFragment> | null;
};

const DocumentCreatingTasks: FunctionComponent<DocumentCreatingTasksProps> = (props) => {
  const deal = useFragment(TaskListDealFragment, props.deal);
  const mypageDealTasks = deal?.mypageDealTasks || [];
  const contractTask = mypageDealTasks.find((task) => task.type === MypageDealTaskTypeEnum.Contract);
  const documentDeliveryTask = mypageDealTasks.find((task) => task.type === MypageDealTaskTypeEnum.DocumentPreparation);
  const isAllCompleted = [contractTask?.status, documentDeliveryTask?.status].every(
    (status) => status === MypageDealTaskStatusEnum.Completed
  );
  const dealContract = deal?.dealContract;

  return (
    <>
      <TaskItem
        taskStatus={contractTask?.status || MypageDealTaskStatusEnum.Preparing}
        title='電子契約サイン'
        deadline={dealContract?.deadline}
        description={
        (contractTask?.status === MypageDealTaskStatusEnum.Completed ||
        contractTask?.status === MypageDealTaskStatusEnum.Active) &&
        dealContract?.userAuthenticationMethod === ContractUserAuthenticationMethodEnum.Email &&
        dealContract?.email ?
        `${dealContract?.email}宛にクラウドサインから電子契約の書類が届いてます。サインしていただけると正式に契約完了します。` :
        dealContract?.userAuthenticationMethod === ContractUserAuthenticationMethodEnum.Sms &&
        dealContract?.smsTel ?
        `LINEで電子契約の書類が届いてます。サインしていただけると正式に契約完了します。SMS認証の電話番号は${dealContract.smsTel}です。` :
        ''
        }
        link={
        dealContract?.userAuthenticationMethod === ContractUserAuthenticationMethodEnum.Email ?
        '/pdf/cloudsign.pdf' :
        undefined
        } />

      <TaskItem
        taskStatus={documentDeliveryTask?.status || MypageDealTaskStatusEnum.Preparing}
        title='書類準備'
        description={
        'バディカダイレクトより登録に必要な書類を発送いたします。お手元に届きましたらご記入・必要書類をご準備いただき、同封のレターパックにてご返送をお願いいたします。'
        }
        link='/pdf/car_docs.pdf' />

      <NextArrow text='登録申請へ' active={isAllCompleted} />
    </>);

};

type RegistrationApplyingTasksProps = {
  deal?: FragmentType<typeof TaskListDealFragment> | null;
};

const RegistrationApplyingTasks: FunctionComponent<RegistrationApplyingTasksProps> = (props) => {
  const deal = useFragment(TaskListDealFragment, props.deal);
  const mypageDealTasks = deal?.mypageDealTasks || [];
  const documentReceiptTask = mypageDealTasks.find((task) => task.type === MypageDealTaskTypeEnum.DocumentReceipt);
  const carRegistrationApplicationTask = mypageDealTasks.find(
    (task) => task.type === MypageDealTaskTypeEnum.DocumentRegistrationApplication
  );
  const carRegistrationCompletionTask = mypageDealTasks.find(
    (task) => task.type === MypageDealTaskTypeEnum.RegistrationCompletion
  );
  const isAllCompleted = [documentReceiptTask, carRegistrationApplicationTask, carRegistrationCompletionTask].every(
    (task) => task?.status === MypageDealTaskStatusEnum.Completed
  );

  return (
    <>
      <TaskItem taskStatus={documentReceiptTask?.status || MypageDealTaskStatusEnum.Active} title='書類受け取り' />
      <TaskItem
        taskStatus={carRegistrationApplicationTask?.status || MypageDealTaskStatusEnum.Active}
        title='登録申請中'
        description={
        '登録完了までの目安日数\n1.車庫証明取得をBUDDICAにご依頼いただいた場合\nお客様から車庫証明申請書類・登録申請書類を受領後10日〜2週間程度\n\n2.車庫証明取得をお客様が手配していただいた場合\nお客様から登録申請書類(車庫証明を含む)を受領後1週間程度\n\nただしお客様のお住まいの都道府県や整備内容により前後します。'
        } />

      <TaskItem
        taskStatus={carRegistrationCompletionTask?.status || MypageDealTaskStatusEnum.Active}
        title='登録完了'
        description={
        deal?.dealDetail?.expectedCarRegistrationCompletionDate &&
        `完了目安は${dayjs(deal.dealDetail.expectedCarRegistrationCompletionDate).format('MM月DD日')}です。`
        } />

      <NextArrow text='お車配送へ' active={isAllCompleted} />
    </>);

};

type DeliveryPreparingTasksProps = {
  deal?: FragmentType<typeof TaskListDealFragment> | null;
};

const DeliveryPreparingTasks: FunctionComponent<DeliveryPreparingTasksProps> = (props) => {
  const deal = useFragment(TaskListDealFragment, props.deal);
  const mypageDealTasks = deal?.mypageDealTasks || [];
  const carRegistrationCompletionTask = mypageDealTasks.find(
    (task) => task.type === MypageDealTaskTypeEnum.DeliveryPreparation
  );
  const carCarryingOutTask = mypageDealTasks.find((task) => task.type === MypageDealTaskTypeEnum.Deliverying);
  const carDelivery = mypageDealTasks.find((task) => task.type === MypageDealTaskTypeEnum.DeliveryDate);
  const isAllCompleted = [carRegistrationCompletionTask, carCarryingOutTask, carDelivery].every(
    (task) => task?.status === MypageDealTaskStatusEnum.Completed
  );

  return (
    <>
      <TaskItem
        taskStatus={carRegistrationCompletionTask?.status || MypageDealTaskStatusEnum.Active}
        title='納車準備中' />

      {carCarryingOutTask &&
      <TaskItem taskStatus={carCarryingOutTask.status || MypageDealTaskStatusEnum.Active} title='配送中' />
      }
      <TaskItem
        taskStatus={carDelivery?.status || MypageDealTaskStatusEnum.Active}
        title={carDelivery?.title || '納車日未定'} />

      <NextArrow text='納車完了' active={isAllCompleted} />
    </>);

};

const DeliveryCompleted: FunctionComponent = () => {
  return (
    <>
      <p>誠にありがとうございました！</p>
      <p>
        BUDDICAの新しいオンライン事業で車をお買い上げいただき、心から感謝申し上げます。この特別な瞬間を共有できることを大変嬉しく思います。
      </p>
      <p>これからもより良いサービスを提供できるよう、日々精進してまいります。</p>
      <p>ご意見や体験についてお聞かせいただけると、とても幸いです。アンケートからのご投稿をお待ちしております。</p>
      <p>
        さらに、新しい愛車とのカーライフが素晴らしいものとなりますよう、心よりお祈り申し上げております。何かお手伝いが必要な場合は、いつでもお知らせください。お客様のカーライフをサポートできることを楽しみにしております。
      </p>
      <p className={styles[`${blockClass}__button`]}>
        <a className='c-button is-primary' href='https://lstep.app/form/49070/rfaUPw/754730'>
          アンケートに回答
        </a>
      </p>
    </>);

};

type Props = {
  group: MypageDealTaskGroupEnum;
  deal?: FragmentType<typeof TaskListDealFragment> | null;
};

export const TaskList: FunctionComponent<Props> = (props) => {
  const { group } = props;

  return (
    <div className={styles[blockClass]}>
      {group === MypageDealTaskGroupEnum.DocumentCreating && <DocumentCreatingTasks {...props} />}
      {group === MypageDealTaskGroupEnum.RegistrationApplication && <RegistrationApplyingTasks {...props} />}
      {group === MypageDealTaskGroupEnum.DeliveryPreparation && <DeliveryPreparingTasks {...props} />}
      {group === MypageDealTaskGroupEnum.DeliveryCompletion && <DeliveryCompleted />}
    </div>);

};